<div *ngIf="!initializing" style="display:flex; height: 100vh; overflow: hidden;">

  <div class="navigation-container">
    <app-navigation></app-navigation>
  </div>

  <div style="height: 100vh; flex-grow: 1; overflow: hidden auto">
    <!--    <div style="height:48px; color:white; position:sticky;top:0;left: 0;width:100%; background-color:#12394c">-->
    <!--      welcome mr folani???-->
    <!--    </div>-->
    <router-outlet></router-outlet>
  </div>
  <ai-assistant #assistant></ai-assistant>

</div>


<atw-upload-toast
  style="display:block;position: fixed;bottom: 10px;right:10px;"></atw-upload-toast>

<div *ngIf="initializing">
  <app-loading/>

</div>
