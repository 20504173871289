import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BasicsRepository } from '../data/repository/basics.repository';
import { AssistantComponent } from '../ai/assistant/assistant.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchDialogComponent } from '../share/components/search-dialog/search-dialog.component';
import { NotificationService } from '../share/services/notification.service';
import * as Sentry from '@sentry/angular';
import { SheetRepository } from '../data/repository/sheet.repository';
import { PeekDialogComponent } from '../views/peek-dialog/peek-dialog.component';
import { SidebarItemComponent } from '../views/sidebar-item/sidebar-item.component';
import { UrlUtil } from '../share/utils/url.util';
import { UrlChangeListener, UrlEvents } from '../events/url.events';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements UrlChangeListener {
  @ViewChild('assistant') assistant: AssistantComponent;

  initializing: boolean = true;
  showTut: boolean = false;

  constructor(
    private router: Router,
    private urlUtil: UrlUtil,
    private urlEvents: UrlEvents,
    private modalService: NgbModal,
    private baseRepo: BasicsRepository,
    private sheetRepo: SheetRepository,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit() {
    await this.baseRepo.fetchDefaults();
    let showAppTour = localStorage.getItem('showAppTour');
    this.showTut = showAppTour !== '0';
    this.initializing = false;
    this.initMessaging();
    this.initSentryUser();
    this.checkParams();
    this.urlEvents.onUrlChange(this);
  }

  initMessaging() {
    this.notificationService.requestPermission();
    this.notificationService.receiveMessage();
  }

  async initSentryUser() {
    let employee = await this.sheetRepo.getMyEmployee();
    Sentry.setUser({
      email: employee.Email,
      id: employee.UserId,
      ip_address: '{{auto}}',
      username: `${employee.FullName.firstName} ${employee.FullName.lastName}`,
    });
  }

  @HostListener('window:keydown.control.b', ['$event'])
  openAssistant(event: Event) {
    this.assistant.open();
    event.preventDefault();
  }

  @HostListener('window:keydown.control.e', ['$event'])
  searchDialog(event: Event) {
    this.modalService.open(SearchDialogComponent, { size: 'lg' });
    event.preventDefault();
  }

  onUrlChange(url: string, params: any) {
    this.checkParams();
  }

  checkParams() {
    let params = this.urlUtil.getQueryParams();
    if (params['sheet'] && params['item']) {
      this.showItemDialog(params['sheet'], params['item'], params['peek']);
    }
  }

  async showItemDialog(sheetId: number, itemId: string, peekId: string | undefined) {
    let sheet = await this.sheetRepo.getSheetById(sheetId);
    if (!peekId) {
      peekId = sheet.options.peek || 'side';
    }
    if (peekId == 'side') {
      let ngbModalRef = this.modalService.open(SidebarItemComponent, {
        windowClass: 'sidebar-modal',
      });
      ngbModalRef.componentInstance.sheet = sheet;
      ngbModalRef.componentInstance.itemId = itemId;
      ngbModalRef.componentInstance.init();
      ngbModalRef.result.finally(() => {
        this.router.navigate([], { queryParams: {} });
      });
    } else {
      let peek = sheet.peeks.find((p) => p.id == peekId);
      if (!peek) return;
      let dialog = this.modalService.open(PeekDialogComponent, {
        size: peek.options.size || 'large',
      });
      dialog.componentInstance.itemId = itemId;
      dialog.componentInstance.sheet = sheet;
      dialog.componentInstance.peek = peek;
      dialog.result.finally(() => {
        this.router.navigate([], { queryParams: {} });
      });
    }
  }
}
